export default class Smart {
    constructor(options = {}) {
        Object.assign(this, {
            order: 0,
            mobileImage: [],
            fullScreenMobileNameImage: '',
            fullScreenMobileNameImageKo: '',
            fullScreenMobileDescImage: '',
            fullScreenMobileVideo: '',
            twinMobileNameImage: '',
            twinMobileDescImage: '',
            creationDateTime: '',
            lastModifiedDateTime: '',
            mobileType: 1, // 1은 전체, 2는 2단모드
        }, options);
        Object.assign(this, options);
    }
}