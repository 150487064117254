export default class Product {
    constructor(options = {}) {
        Object.assign(this, {
            name: '',
            order: 0,
            productImage: '',
            productDescImage: '',
            productVideo: '',
            creationDateTime: '',
            lastModifiedDateTime: '',
            hash: '',
        }, options);
        Object.assign(this, options);
    }
}