<template>
  <div id="contents" class="contents smart page" v-if="smarts">
    <div class="content_head">
      <!-- s: 페이지 타이틀 -->
      <div class="page_ttl_wrap has_btn">
        <h2 class="page_ttl">스마트 모바일 체험대_삼성</h2>
        <div class="btn_group">
          <span>최근 발행일시 {{recentPublishTime ? moment(recentPublishTime).format('YYYY.MM.DD HH:mm:ss') : '없음'}}</span>
          <!-- Dev: 변경사항이 없을시 비활성화 -->
          <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
        </div>
      </div>
    </div>
    <!-- e: 페이지 타이틀 -->
    <!-- s: content_body -->
    <div class="content_body">
      <!-- s: item_list_sec-->
      <div class="item_list_sec">

        <!-- Dev: 상품 추가 wrap -->
<!--        <div class="item_list_wrap none min" :class="{is_active : clickedProductHash == null}"
             @click="clickAddBtn()">
          <div class="item_list_head min">
            <div class="item_thumb">
              <button>추가버튼</button>
            </div>
          </div>
        </div>-->
        <!-- Dev : 등록된 스마트모바일  -->
        <div class="registration_item_wrap">
          <Container class="registration_item_area"
                     orientation="horizontal"
                     tag="div"
                     :drop-placeholder="dropPlaceholderOptions"
                     @drop="onDrop">
            <Draggable class="item_list_wrap min" v-for="(item, index) in smarts" :key="'item' + item.hash"
                       :class="{is_active : item.hash === clickedSmartHash}"
                       @click="clickSmart(item, item.hash)">
              <div class="item_list_head min"
                   :class="{'has_img' : item.mobileImage.length > 0 ? item.mobileImage[0] : ''}">
                <div class="item_thumb"
                     :style="`background-image: url(${getFile(item.mobileImage.length > 0 ? item.mobileImage[0] : null)})`">
                </div>
                <!--                                <div class="delete_btn_area">
                                                    <button class="delete_btn" @click="removeList(item.hash)">삭제버튼</button>
                                                </div>-->
              </div>
            </Draggable>
          </Container>
        </div>
      </div>
      <!-- e: item_list_sec-->

      <!-- s : template_sec -->
      <div class="template_sec">
        <div class="template_wrap">
          <div class="template_label">
            <p>템플릿 타입 선택</p>
          </div>
          <div class="template_mode_area">
            <button class="btn w_sl h_l" v-for="(item, i) in typeList" :key="'type' + i"
                    :class="{strong : item.value === clickedSmart.mobileType}"
                    @click="changeType(item)">
              {{item.type}}
            </button>
          </div>
        </div>
      </div>

      <!-- e : template_sec -->

      <!-- s: preview_sec -->
      <div class="preview_sec">
        <div class="preview_wrap">
          <!-- Dev : psd 다운로드 버튼 추가 -->
          <a class="psd_down_btn" :href="`${publicPath}file/smart.zip`" download="">
            PSD 다운로드
          </a>
          <div class="upload_guide" role="button">
            <div class="tooltip_area">
              <img src="../../../assets/img/common/tooltip.png" alt="">
              <p>파일 업로드 가이드</p>
            </div>
            <div class="layer_info">
              <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                <div class="ttl">{{item.ttl}}</div>
                <div class="cnt">
                  <p>{{item.file}}</p>
                  <p>{{item.memory}}</p>
                  <p>{{item.display}}</p>
                </div>
              </div>
              <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
            </div>
          </div>
          <div class="preview_area"
               :class="{'smart2' : clickedSmart.mobileType === 2}">
            <div class="samsung_area">
              <!--  Dev s : 등록된 파일이 없을경우 -->
              <!--  Dev s : 등록된 파일이 없을경우 -->
              <template v-if="!mobileNameImage">
                <div class="smart_product_name">
                  <div class="upload_btn smart" role="button">
                    <div class="btn_area">
                      <label for="name_en"></label>
                      <input id="name_en" type="file" accept="image/png"
                             @change="onFileChange($event, 'mobileNameImage')">
                    </div>
                    <div class="info_area">
                      <p v-if="clickedSmart.mobileType === 1">png, 510*60</p>
                      <P v-if="clickedSmart.mobileType === 2">png, 500*50</P>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="!mobileNameImageKo">
                <!--  Dev: s: 211230 추가 핸드폰명 한.영 구분  -->
                <div v-if="clickedSmart.mobileType === 1" class="smart_product_name ko_name">
                  <div class="upload_btn smart" role="button">
                    <div class="btn_area">
                      <label for="name_ko"></label>
                      <input id="name_ko" type="file" accept="image/png"
                             @change="onFileChange($event, 'mobileNameImageKo')">
                    </div>
                    <div class="info_area">
                      <p>png, 510*30</p>
                    </div>
                  </div>
                </div>
                <!--  Dev: e: 211230 추가 핸드폰명 한.영 구분  -->
              </template>
              <!--  Dev e : 등록된 파일이 없을경우 -->

              <!-- Dev s: 등록된 파일 있을 경우 -->
              <template v-if="mobileNameImage">
                <div class="smart_product_name exist">
                  <div class="name_box" :style="`background-image: url('${getFile(mobileNameImage)}')`">
                    <div class="hover_btn_area">
                      <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                      <button class="modify_btn">
                        <img src="../../../assets/img/common/video_modify.png" alt="">
                        <input type="file" accept="image/png"
                               @change="onFileChange($event, 'mobileNameImage')">
                      </button>
                      <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                      <button class="delete_btn" @click="deleteImage('mobileNameImage')">삭제</button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="mobileNameImageKo && clickedSmart.mobileType === 1">
                <!--  Dev: s: 211230 추가 핸드폰명 한.영 구분  -->
                <div class="smart_product_name exist ko_name">
                  <div class="name_box" :style="`background-image: url('${getFile(mobileNameImageKo)}')`">
                    <div class="hover_btn_area">
                      <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                      <button class="modify_btn">
                        <img src="../../../assets/img/common/video_modify.png" alt="">
                        <input type="file" accept="image/png"
                               @change="onFileChange($event, 'mobileNameImageKo')">
                      </button>
                      <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                      <button class="delete_btn" @click="deleteImage('mobileNameImageKo')">삭제</button>
                    </div>
                  </div>
                </div>
                <!--  Dev: e: 211230 추가 핸드폰명 한.영 구분  -->
              </template>
              <!-- Dev e: 등록된 파일 있을 경우 -->
              <!-- Dev e: 등록된 파일 있을 경우 -->

              <!-- Dev s: 등록된 파일 없을 경우 mobileImage -->
              <template v-if="!clickedSmart.mobileImage || clickedSmart.mobileImage.length === 0 || mobileImageEditMode">
                <div class="thumb_area exist">
                  <div class="thumb_slider mob_img">
                    <div class="slide_area">
                      <div class="swiper_pagination"></div>
                      <swiper @swiper="setControlledSwiper"
                              :slidesPerView="1.2"
                              :spaceBetween="10"
                              :loop="false"
                              :pagination=" { el: '.swiper_pagination' }"
                              :navigation=" { nextEl: '.swiper_nav_next', prevEl: '.swiper_nav_prev' }"
                      >
                        <swiper-slide>
                          <div class="upload_btn_wrap">
                            <div class="upload_btn" role="button">
                              <div class="txt_area">
                                <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                              </div>
                              <div class="btn_area">
                                <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                <input type="file"
                                       accept="image/gif,image/jpeg,image/png,video/mp4"
                                       @change="onFileChange($event, 'mobileImage')"
                                >
                              </div>
                              <div class="info_area">
                                <p>png, 344*444</p>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                        <!-- Dev s: 이미지 추가될 경우 구조 -->
                        <swiper-slide v-if="clickedSmart.mobileImage?.length > 0"
                                      v-for="(item, index) in clickedSmart.mobileImage">
                          <div class="model_img_wrap">
                            <div class="model_img_box"
                                 :style="`background-image: url('${getFile(item)}')`">
                            </div>
                            <div class="hover_btn_area" v-if="mobileImageEditMode">
                              <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                              <button class="modify_btn">
                                <img src="../../../assets/img/common/video_modify.png" alt="">
                                <input type="file" accept="image/gif,image/jpeg,image/png,video/mp4"
                                       @change="onFileChange($event, 'mobileImage', index)">
                              </button>
                              <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                              <button class="delete_btn" @click="deleteImage('mobileImage', index)">삭제</button>
                            </div>
                          </div>
                        </swiper-slide>
                        <!-- Dev e: 이미지 추가될 경우 구조 -->
                      </swiper>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Dev e: 등록된 파일 없을 경우 mobileImage -->

              <!-- Dev s: 등록된 파일 있을 경우 mobileImage -->
              <template v-else>
                <div class="thumb_area exist">
                  <div class="editor_btn_wrap">
                    <button class="editor_btn" @click="mobileImageEditMode = true">
                      <img src="../../../assets/img/common/vdm_search.png" alt="">
                    </button>
                  </div>
                  <div class="thumb_slider mob_img view">
                    <div class="slide_area">
                      <swiper @swiper="setControlledSwiper"
                              :slidesPerView="1"
                              :spaceBetween="10"
                              :loop="true"
                              :autoplay="300"
                              :effect="'fade'"

                      >
                        <swiper-slide v-for="(item, index) in clickedSmart.mobileImage">
                          <div class="model_img_wrap">
                            <div class="model_img_box"
                                 :style="`background-image: url('${getFile(item)}')`">
                            </div>
                            <div class="hover_btn_area" v-if="mobileImageEditMode">
                              <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                              <button class="modify_btn">
                                <img src="../../../assets/img/common/video_modify.png" alt="">
                                <input type="file" accept="image/gif,image/jpeg,image/png,video/mp4"
                                       @change="onFileChange($event, 'mobileImage', index)">
                              </button>
                              <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                              <button class="delete_btn" @click="deleteImage('mobileImage', index)">삭제</button>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Dev e: 등록된 파일 있을 경우 mobileImage -->

              <!-- Dev s: 등록된 파일 없을 경우 -->
              <template v-if="!mobileVideo">
                <div class="video_area">
                  <div class="upload_btn" role="button">
                    <div class="txt_area">
                      <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                    </div>
                    <div class="btn_area">
                      <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                      <input type="file" accept="video/mp4" @change="onFileChange($event, 'mobileVideo')">
                    </div>
                    <div class="info_area">
                      <p>mp4, 1920*1080</p>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Dev e: 등록된 파일 없을 경우 -->

              <!-- Dev s: 등록된 파일 있을 경우 -->
              <template v-else>
                <div class="video_area exist">
                  <video class="video_box" style="background:#333;" :src="getFile(mobileVideo)">
                  </video>
                  <button class="video_play_btn play" @click="playVideo('play')" v-if="playStatus">
                    <img src="../../../assets/img/common/video_play_btn.png" alt="">
                  </button>
                  <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                    <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                  </button>
                  <template v-if="mobileVideo">
                    <div class="hover_btn_area">
                      <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                      <button class="modify_btn">
                        <img src="../../../assets/img/common/video_modify.png" alt="">
                        <input type="file" accept="video/mp4"
                               @change="onFileChange($event, 'mobileVideo')">
                      </button>
                      <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                      <button class="delete_btn" @click="deleteImage('mobileVideo')">삭제</button>
                    </div>
                  </template>
                </div>
              </template>
              <!-- Dev e: 등록된 파일 있을 경우 -->

              <div class="advantage_wrap">
                <!-- Dev s: 등록된 파일 없을 경우 -->
                <template v-if="!mobileDescImage">
                  <div class="advantage_area">
                    <div class="upload_btn" role="button">
                      <div class="txt_area">
                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                      </div>
                      <div class="btn_area">
                        <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                        <input type="file" accept="image/png" @change="onFileChange($event, 'mobileDescImage')">
                      </div>
                      <div class="info_area">
                        <p v-if="clickedSmart.mobileType === 1">png, 1840*250</p>
                        <p v-if="clickedSmart.mobileType === 2">png, 910*360</p>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- Dev e: 등록된 파일 없을 경우 -->

                <!-- Dev s: 등록된 파일 있을 경우 -->
                <template v-else>
                  <div class="advantage_area exist">
                    <div class="advantage_box exist" :style="`background-image: url('${getFile(mobileDescImage)}')`">
                      <template v-if="mobileDescImage">
                        <div class="hover_btn_area">
                          <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                          <button class="modify_btn">
                            <img src="../../../assets/img/common/video_modify.png" alt="">
                            <input type="file" accept="image/png"
                                   @change="onFileChange($event, 'mobileDescImage')">
                          </button>
                          <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                          <button class="delete_btn" @click="deleteImage('mobileDescImage')">삭제</button>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- Dev e: 등록된 파일 있을 경우 -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e: preview_sec -->
    </div>
    <!-- e: content_body -->

    <!-- s: content_foot -->
    <div class="content_foot">
      <div class="btn_group center">
        <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
        <!--                <button v-if="clickedSmartHash" class="btn basic w_m h_l" @click="resetSmart">취소</button>-->
        <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
        <button v-if="clickedSmartHash" class="btn strong w_m h_l" @click="saveSmart">저장</button>
        <button v-else class="btn strong w_m h_l" @click="createSmart">저장</button>
      </div>
    </div>
    <!-- e : content_foot  -->
  </div>
  <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
          @yesDialog="deleteSmart()">
    <template #body>
      <h3>삭제 확인</h3>
      <p>선택한 스마트모바일 체험대를 삭제하시겠습니까?<br>
        변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다</p>
    </template>
  </Dialog>

  <!-- Dev :  페이지 나가기, 새로고침, 브라우저 닫기 시 호출 -->
  <Dialog :isOpen="pageOutDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="pageOutDialogState = false"
          @yesDialog="pageOut()">
    <template #body>
      <h3>페이지에서 나가시겠습니까?</h3>
      <p>변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다.</p>
    </template>
  </Dialog>
</template>

<script>
import { Container, Draggable } from "vue3-smooth-dnd";
// import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
// import "swiper/css/effect-fade";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/core';
import moment from "moment";
import Dialog from "@/components/dialog";
import Smart from "@/plugins/firebase/model/smart";
import { applyDrag } from "@/plugins/dnd-helpers";
import Product from "@/plugins/firebase/model/product";

SwiperCore.use([EffectFade, Autoplay, Navigation, Pagination]);


export default {
  name: "index",
  layout: "new-admin",
  components: {
    Container: Container,
    Draggable: Draggable,
    Dialog,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,
      typeList: [
        {
          type: '전체 모드',
          value: 1,
        },
        {
          type: '2단 모드',
          value: 2,
        },
      ],
      cardList: [
        {
          file: "iPhone 12\n" +
              "Pro Max",
        },
        {
          file: "iPhone 12\n" +
              "Pro",
        },
        {
          file: "iPhone 12"
        },
        {
          file: "iPhone 12\n" +
              "Mini",
        },
      ],
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      imgFile: true,
      isActive: false,
      activeIndex: 0,
      playStatus: true,
      deleteDialogState: false,
      pageOutDialogState: false,
      tooltip: [
        {
          ttl: '영어 핸드폰 명(전체)',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 510*60',
        },
        {
          ttl: '한글 핸드폰 명(전체)',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 510*30',
        },
        {
          ttl: '영어 핸드폰 명(2단)',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 500*50',
        },
        {
          ttl: '핸드폰 이미지',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 344*444',
        },
        {
          ttl: '핸드폰 설명 이미지(전체)',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 1840*250',
        },
        {
          ttl: '핸드폰 설명 이미지(2단)',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 910*360',
        },
        {
          ttl: '영상',
          file: 'MP4',
          memory: '용량 80MB 이하',
          display: '해상도 1920*1080',
        }
      ],

      smart_publish_key: 'publish/smartSamsung',
      smart_root_key: 'smartSamsung',
      recentPublishTime: '',
      smarts: null,
      originalSmarts: {},
      clickedSmart: new Smart(),
      clickedSmartHash: null,
      deleteSmartHash: null,
      mobileNameImage: '',
      mobileNameImageKo: '',
      mobileImage: '',
      mobileVideo: '',
      mobileDescImage: '',
      canLeaveSite: true,
      isUploading: false,
      mobileImageEditMode: false,
    }
  },
  methods: {
    async publish() {
      const time = this.timeToString(moment());
      await this.$firebase.database().ref(this.smart_publish_key).child('lastPublishDateTime').set(time);
      this.toastSuccess('발행 완료.');
    },
    onFileChange(e, key, index) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      let file = files[0];

      const isImage = this.isImage(file);
      const isVideo = this.isVideo(file);
      const maxImageSize = 1024 * 1024;
      const maxVideoSize = 80 * 1024 * 1024;
      if (isImage) {
        if (maxImageSize <= file.size) {
          this.toastDanger('이미지 용량은 1MB 이하로 등록해주세요.');
          return;
        }
      } else if (isVideo) {
        if (maxVideoSize <= file.size) {
          this.toastDanger('영상 용량은 80MB 이하로 등록해주세요.');
          return;
        }
      } else {
        this.toastDanger('이미지는 PNG 파일, 영상은 MP4만 가능합니다.');
        return;
      }
      this[key] = file;
      if (key === 'mobileImage') {
        if (index >= 0) {
          this.clickedSmart.mobileImage[index] = file;
        }
        if (!index || index === 0) {
          // 첫번째 이미지거나, 없을 때
          this.mobileImage = file;
          this.clickedSmart.mobileImage.push(file);
        }
      }
      if (this.clickedSmart.mobileType === this.typeList[0].value) {
        if (key === 'mobileNameImage') this.clickedSmart.fullScreenMobileNameImage = file;
        else if (key === 'mobileNameImageKo') this.clickedSmart.fullScreenMobileNameImageKo = file;
        else if (key === 'mobileVideo') this.clickedSmart.fullScreenMobileVideo = file;
        else if (key === 'mobileDescImage') this.clickedSmart.fullScreenMobileDescImage = file;
      } else if (this.clickedSmart.mobileType === this.typeList[1].value) {
        if (key === 'mobileNameImage') this.clickedSmart.twinMobileNameImage = file;
        else if (key === 'mobileDescImage') this.clickedSmart.twinMobileDescImage = file;
      }
      this.canLeaveSite = false;
    },
    deleteImage(key, index) {
      if (key === 'mobileImage') {
        if (index >= 0) {
          //this.clickedSmart.mobileImage[index] = '';
          this.clickedSmart.mobileImage.splice(index, 1);
        }
        if (!index || index === 0) {
          // 첫번째 이미지거나, 없을 때
          this.mobileImage = '';
        }
      }
      if (this.clickedSmart.mobileType === this.typeList[0].value) {
        if (key === 'mobileNameImage') {
          this.mobileNameImage = '';
          this.clickedSmart.fullScreenMobileNameImage = '';
        } else if (key === 'mobileNameImageKo') {
          this.mobileNameImageKo = '';
          this.clickedSmart.fullScreenMobileNameImageKo = '';
        } else if (key === 'mobileVideo') {
          this.mobileVideo = '';
          this.clickedSmart.fullScreenMobileVideo = '';
        } else if (key === 'mobileDescImage') {
          this.mobileDescImage = '';
          this.clickedSmart.fullScreenMobileDescImage = '';
        }
      } else if (this.clickedSmart.mobileType === this.typeList[1].value) {
        if (key === 'mobileNameImage') {
          this.mobileNameImage = '';
          this.clickedSmart.twinMobileNameImage = '';
        } else if (key === 'mobileDescImage') {
          this.mobileDescImage = '';
          this.clickedSmart.twinMobileDescImage = '';
        }
      }
    },
    onDrop(dropResult) {
      if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
        this.smarts[dropResult.removedIndex].order = dropResult.addedIndex + 1;
        this.smarts[dropResult.addedIndex].order = dropResult.removedIndex + 1;
      }
      this.smarts = applyDrag(this.smarts, dropResult);
      this.smarts.forEach((item, index) => {
        item.order = index + 1;
        var original = this.originalSmarts[item.hash];
        original.order = index + 1;
      });
      this.smarts = this.smarts.sort((a, b) => {
        return a.order - b.order;
      });
      this.smarts.forEach((item) => {
        this.$firebase.database().ref(this.smart_root_key).child(item.hash).set(item);
      });

      const removed = this.smarts[dropResult.removedIndex];
      const added = this.smarts[dropResult.addedIndex];
      this.$firebase.database().ref(this.smart_root_key).child(removed.hash).set(removed);
      this.$firebase.database().ref(this.smart_root_key).child(added.hash).set(added);
      this.clickSmart(added, added.hash);

      this.canLeaveSite = false;
    },

    playVideo(status) {
      const video = document.querySelector('.video_box');
      if (status === 'play') {
        video.play();
        this.playStatus = false;
      } else {
        video.pause();
        this.playStatus = true;
      }
    },
    deleteSmart() {
      this.deleteDialogState = false;
      this.toastSuccess('스마트모바일 체험대가 삭제 되었습니다.');
      this.canLeaveSite = false;
      // this.redirect('/vmd');
    },
    removeList() {
      this.deleteDialogState = true;
    },
    pageOut() {
      this.pageOutDialogState = false;
    },
    changeType(type) {
      this.clickedSmart.mobileType = type.value;
      this.mobileImage = this.clickedSmart.mobileImage;
      if (type.value === this.typeList[0].value) {
        this.mobileNameImage = this.clickedSmart.fullScreenMobileNameImage;
        this.mobileNameImageKo = this.clickedSmart.fullScreenMobileNameImageKo;
        this.mobileVideo = this.clickedSmart.fullScreenMobileVideo;
        this.mobileDescImage = this.clickedSmart.fullScreenMobileDescImage;
      } else if (type.value === this.typeList[1].value) {
        this.mobileNameImage = this.clickedSmart.twinMobileNameImage;
        this.mobileVideo = '';
        this.mobileDescImage = this.clickedSmart.twinMobileDescImage;
      }
    },
    reloadData() {
      this.$firebase.database().ref(this.smart_publish_key).on('value', (sn) => {
        const publish = sn.val() || {};
        if (publish) {
          this.recentPublishTime = publish.lastPublishDateTime;
        }
      });
      return this.$firebase.database().ref(this.smart_root_key).once('value', (sn) => {
        const smarts = sn.val() || {};
        const smartArr = [];
        for (const [hash, smart] of Object.entries(smarts)) {
          smart.hash = hash;
          smartArr.push(smart);
        }
        this.smarts = smartArr.sort((a, b) => a.order - b.order);
      });
    },
    clickSmart(item, hash) {
      const prevHash = this.clickedSmart.hasOwnProperty('hash') ? this.clickedSmart.hash : null;
      if (prevHash && this.originalSmarts.hasOwnProperty(prevHash)) {
        const original = this.originalSmarts[prevHash];
        const find = this.smarts.findIndex((item) => item.hash === prevHash);
        if (find > -1) {
          this.smarts[find] = JSON.parse(JSON.stringify(original));
        }
      }

      this.clickedSmart = item;
      this.clickedSmartHash = hash;
      this.mobileImage = this.clickedSmart.mobileImage;
      if (this.clickedSmart.mobileType === this.typeList[0].value) {
        this.mobileNameImage = this.clickedSmart.fullScreenMobileNameImage;
        this.mobileNameImageKo = this.clickedSmart.fullScreenMobileNameImageKo;
        this.mobileVideo = this.clickedSmart.fullScreenMobileVideo;
        this.mobileDescImage = this.clickedSmart.fullScreenMobileDescImage;
      } else if (this.clickedSmart.mobileType === this.typeList[1].value) {
        this.mobileNameImage = this.clickedSmart.twinMobileNameImage;
        this.mobileVideo = '';
        this.mobileDescImage = this.clickedSmart.twinMobileDescImage;
      }
      this.mobileImageEditMode = false;
    },
    resetSmart() {
      this.clickedProduct = new Smart();
      this.mobileNameImage = '';
      this.mobileNameImageKo = '';
      this.mobileImage = '';
      this.mobileVideo = '';
      this.mobileDescImage = '';
    },
    setControlledSwiper(swiper) {
      if (swiper.slides.length > 1) this.controlledSwiper = swiper;
    },
    async saveSmart() {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.loadingOverlay(async (loader) => {
        const smart = this.clickedSmart;
        smart.creationDateTime = this.timeToString(moment());
        smart.lastModifiedDateTime = this.timeToString(moment());
        const hash = smart.hash;
        let files = [smart.fullScreenMobileNameImage, smart.fullScreenMobileNameImageKo, smart.fullScreenMobileDescImage, smart.fullScreenMobileVideo, smart.twinMobileNameImage, smart.twinMobileDescImage];
        let temps = [];
        if (files.length > 0) {
          this.$Progress.start();
          for await (const file of files) {
            const index = files.indexOf(file);
            temps[index] = '';
            if (file && file instanceof File) {
              let downloadUrl = await this.upload(hash, file, index, files.length);
              temps[index] = downloadUrl;
            }
          }
          //smart.mobileImage = temps[0] ? temps[0] : smart.mobileImage;
          smart.fullScreenMobileNameImage = temps[0] ? temps[0] : smart.fullScreenMobileNameImage;
          smart.fullScreenMobileNameImageKo = temps[1] ? temps[1] : smart.fullScreenMobileNameImageKo;
          smart.fullScreenMobileDescImage = temps[2] ? temps[2] : smart.fullScreenMobileDescImage;
          smart.fullScreenMobileVideo = temps[3] ? temps[3] : smart.fullScreenMobileVideo;
          smart.twinMobileNameImage = temps[4] ? temps[4] : smart.twinMobileNameImage;
          smart.twinMobileDescImage = temps[5] ? temps[5] : smart.twinMobileDescImage;
          this.$Progress.finish();
          smart.lastModifiedDateTime = this.timeToString(moment());
        }
        if (smart.mobileImage.length > 0) {
          this.$Progress.start();
          for await (const file of smart.mobileImage) {
            const index = smart.mobileImage.indexOf(file);
            if (file && file instanceof File) {
              smart.mobileImage[index] = await this.upload(hash, file, index, files.length);
            }
          }
          this.$Progress.finish();
        }

        await this.$firebase.database().ref(this.smart_root_key).child(hash).set(smart).then(() => {
          this.toastSuccess('체험대가 수정되었습니다.');
          this.reloadData().then((data) => {
            if (data) {
              const smarts = data.val();
              if (smarts) {
                for (const [hash, smart] of Object.entries(smarts)) {
                  smart.hash = hash;
                  this.originalSmarts[hash] = JSON.parse(JSON.stringify(smart));
                }
              }
            }
          });
          //this.redirect('/channel');
        });

        this.isUploading = false;
        loader.hide();
        this.mobileImageEditMode = false;
      });
    },
    async upload(hash, image, current, total) {
      let storageRef = this.$firebase.storage().ref();
     /* console.log(storageRef, 1)
      console.log(hash)
      console.log(storageRef.child(hash).child(image.name), 3)*/
      let imageRef = storageRef.child(hash).child(image.name);
      let uploadTask = imageRef.put(image);
      return new Promise(((resolve, reject) => {
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
          this.$Progress.set(gage);
          //console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case this.$firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        }, (error) => {
          console.error('[ERROR] state_changed', error);
          reject(error);
        }, () => {
          // 완료 다운로드 예시
          resolve(uploadTask.snapshot.ref.getDownloadURL());
        });
      }));
    },
    unLoadEvent: function (event) {
      if (this.canLeaveSite) {
        return;
      }
      event.preventDefault();
      event.returnValue = '';
    },

    clickAddBtn() {
      this.clickedSmart = new Smart();
      this.clickedSmartHash = null;
      this.mobileNameImage = '';
      this.mobileNameImageKo = '';
      this.mobileImage = [];
      this.mobileVideo = '';
      this.mobileDescImage = '';
    },
    async createSmart() {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.loadingOverlay(async (loader) => {
        const smart = this.clickedSmart;
        smart.order = 1;
        for await (const item of this.smarts) {
          const index = this.smarts.indexOf(item);
          item.order = index + 2;
          await this.$firebase.database().ref(this.smart_root_key).child(item.hash).set(item);
        }
        smart.creationDateTime = this.timeToString(moment());
        smart.lastModifiedDateTime = this.timeToString(moment());
        const hash = this.$firebase.database().ref(this.smart_root_key).push(smart).key;;
        let files = [smart.fullScreenMobileNameImage, smart.fullScreenMobileNameImageKo, smart.fullScreenMobileDescImage, smart.fullScreenMobileVideo, smart.twinMobileNameImage, smart.twinMobileDescImage];
        let temps = [];
        this.$Progress.start();
        for await (const file of files) {
          const index = files.indexOf(file);
          temps[index] = '';
          if (file && file instanceof File) {
            let downloadUrl = await this.upload(hash, file, index, files.length);
            temps[index] = downloadUrl;
          }
        }
        smart.fullScreenMobileNameImage = temps[0];
        smart.fullScreenMobileNameImageKo = temps[1];
        smart.fullScreenMobileDescImage = temps[2];
        smart.fullScreenMobileVideo = temps[3];
        smart.twinMobileNameImage = temps[4];
        smart.twinMobileDescImage = temps[5]

        this.$Progress.finish();
        smart.lastModifiedDateTime = this.timeToString(moment());
        if (smart.mobileImage.length > 0) {
          this.$Progress.start();
          for await (const file of smart.mobileImage) {
            const index = smart.mobileImage.indexOf(file);
            if (file && file instanceof File) {
              smart.mobileImage[index] = await this.upload(hash, file, index, files.length);
            }
          }
          this.$Progress.finish();
        }

        await this.$firebase.database().ref(this.smart_root_key).child(hash).set(smart).then(() => {
          this.toastSuccess('체험대가 수정되었습니다.');
          this.reloadData().then((data) => {
            if (data) {
              const smarts = data.val();
              if (smarts) {
                for (const [hash, smart] of Object.entries(smarts)) {
                  smart.hash = hash;
                  this.originalSmarts[hash] = JSON.parse(JSON.stringify(smart));
                }
              }
            }
          });
          //this.redirect('/channel');
        });

        this.isUploading = false;
        loader.hide();
        this.mobileImageEditMode = false;
      });
    },
  },
  created() {
    this.reloadData().then((data) => {
      if (data) {
        const smarts = data.val();
        if (smarts) {
          for (const [hash, smart] of Object.entries(smarts)) {
            smart.hash = hash;
            this.originalSmarts[hash] = JSON.parse(JSON.stringify(smart));
          }
        }
      }
      if (this.smarts && this.smarts.length > 0) {
        const smart = this.smarts[0];
        this.clickSmart(smart, smart.hash);
      } else {
        this.clickAddBtn();
      }
    });
  },
  mounted() {
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  beforeRouteLeave(to, from, next) {
    if (this.canLeaveSite) next();
    else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
      next();
    }
  }
}
</script>

<style scoped>

</style>